import { useCallback } from 'react'

import { useRect } from 'react-use-rect'
import { UseEmbeddedRectEvents } from './types'

import useApp from '~/hooks/useApp'

import { isClient } from '~/utils/helpers'

import type { Rect } from 'react-use-rect'
import type * as Types from './types'

const useEmbeddedRect: Types.UseEmbeddedRect = (length?: number) => {
    const { id } = useApp()

    const trigger = useCallback((rect: Rect) => {
        if (isClient && window !== window.parent) {
            const message = {
                type: UseEmbeddedRectEvents.RECT,
                payload: rect,
                appId: id,
                shouldShow: length && length > 0
            }
            window.parent.postMessage(message, '*')
        }
    }, [id])

    const [rectRefSetter, revalidate] = useRect(trigger, { resize: true })

    return {
        rectRefSetter,
        revalidate,
        trigger: trigger
    }
}

export default useEmbeddedRect