import DateUtility from '@gotamedia/fluffy/date'

const format = (value: string): string => {
    const formatdDate = DateUtility.format(new Date(value), 'PPP', {
        locale: DateUtility.locales.sv
    })

    return formatdDate
}

const difference = (value: string) => {
    const relativeDate = DateUtility.differenceInDays(new Date(value), new Date())

    return relativeDate
}

export {
    format,
    difference
}